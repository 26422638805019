import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@core/auth';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  },
  {
    path: 'software-functions',
    loadChildren: () =>
      import('./modules/docs-pages/software-functions/software-functions.module').then(
        (m) => m.SoftwareFunctionsModule
      ),
  },
  {
    path: 'software-manual',
    loadChildren: () =>
      import('./modules/docs-pages/software-manual/software-manual.module').then(
        (m) => m.SoftwareManualModule
      ),
  },
  {
    path: 'software-info',
    loadChildren: () =>
      import('./modules/docs-pages/software-info/software-info.module').then(
        (m) => m.SoftwareInfoModule
      ),
  },
  {
    path: 'privacy',
    loadChildren: () =>
      import('./modules/docs-pages/privacy/privacy.module').then((m) => m.PrivacyModule),
  },
  {
    path: 'wb-oauth-redirect',
    loadChildren: () => import('./modules/wb-oauth/wb-oauth.module').then((m) => m.WbOauthModule),
  },
  {
    path: 'question',
    loadChildren: () =>
      import('./modules/mini-app/question/question.module').then((m) => m.QuestionModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./_metronic/layout/layout.module').then((m) => m.LayoutModule),
  },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
