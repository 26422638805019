import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ThemeModeService } from './_metronic/partials/layout/theme-mode-switcher/theme-mode.service';
import { AuthService, UserType } from '@core/auth';
import { Observable, Subject } from 'rxjs';
import { Metrika } from 'ng-yandex-metrika';
import { takeUntil } from 'rxjs/operators';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  user$: Observable<UserType>;

  public destroy$: Subject<void> = new Subject<void>();

  constructor(
    private modeService: ThemeModeService,
    private auth: AuthService,
    private ym: Metrika
  ) {}

  ngOnInit() {
    const oldDomain = 'seller.indigolab.io';
    const newDomain = 'seller.ilai.io';
    if (window.location.hostname === oldDomain) {
      window.location.href = window.location.href.replace(oldDomain, newDomain);
    }
    this.modeService.init();
    this.user$ = this.auth.currentUserSubject.asObservable();
    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user) {
        const userId = user.id;
        this.ym.setUserID(String(userId));
        this.ym.userParams({
          UserID: userId,
        });
      }
    });

    const referrerUrl = 'https://seller.wildberries.ru/';
    const referrerKey = 'wildberriesReferrer';

    if (document.referrer === referrerUrl) {
      const expirationTime = new Date().getTime() + 5 * 60 * 1000; // 5 минут
      localStorage.setItem(
        referrerKey,
        JSON.stringify({ referrer: document.referrer, expiration: expirationTime })
      );

      // Удаляем из localStorage через 5 минут
      setTimeout(
        () => {
          const savedData = localStorage.getItem(referrerKey);
          if (savedData) {
            const parsedData = JSON.parse(savedData);
            const currentTime = new Date().getTime();

            if (currentTime >= parsedData.expiration) {
              localStorage.removeItem(referrerKey);
            }
          }
        },
        5 * 60 * 1000
      ); // 5 минут
    }

    this.saveUTMToLocalStorage();
  }

  // для извлечения UTM-меток из URL и сохранения в localStorage
  saveUTMToLocalStorage() {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams: { [key: string]: string } = {};

    // Ищем UTM метки в адресной строке
    urlParams.forEach((value, key) => {
      if (key.startsWith('utm_')) {
        utmParams[key] = value;
      }
    });

    // Сохраняем UTM метки в localStorage
    if (Object.keys(utmParams).length > 0) {
      localStorage.setItem('utmParams', JSON.stringify(utmParams));
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
